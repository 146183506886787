import React from "react";

import { confirmAlert } from "react-confirm-alert";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import RadioBox from "../../Components/FormElements/RadioBox";
import InputBox from "../../Components/FormElements/InputBox";
import SelectBox from "../../Components/FormElements/SelectBox";
import CommonButton from "../../Components/FormElements/CommonButton";

import { BackIcon } from "../../Components/svgElements/BackIcon";
import { ImageIcon } from "../../Components/svgElements/ImageIcon";
import { DeleteIcon } from "../../Components/svgElements/DeleteIcon";
import { PrimaryInfoIcon } from "../../Components/svgElements/PrimaryInfoIcon";

import ProductHeader from "./ProductHeader";
import PlaceholderImage from "../../assets/images-2/dummyUser.png";

import { getAttributeList } from "../../Redux/Actions/attributeAction";
import { uploadProductFiles } from "../../Redux/Actions/fileUploadAction";
import {
  addProductAttributes,
  getProductDetailNew,
} from "../../Redux/Actions/productAction";

import "./AddProduct.scss";

import imageCompression from "browser-image-compression";
import { File_SIZE, IMAGE_COMPRESS_OPTION } from "../../Constant/Constant";

import AddAttribute from "./AddAttributeModel";
import { AddIcon } from "../../Components/svgElements/AddIcon";

const ProductAttributes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const productId = location.state.productId;
  const categoryId = location.state.categoryId;
  const status = location.state.sectionStatus;
  const isCategoryChanged = location.state.isCategoryChanged;
  const [globalData, setGlobalData] = React.useState({
    price: "",
    image: {
      attributeImages: [],
      attributeImagesFile: [],
    },
  });
  const [primaryAttribute, setPrimaryAttribute] = React.useState({
    price: [],
    image: {
      attributeImages: [],
      attributeImagesFile: [],
    },
  });
  const [primary, setPrimary] = React.useState({
    isPrimary: false,
    primaryIndex: -1,
  });
  const [loading, setLoading] = React.useState(false);
  const [attribute, setAttribute] = React.useState([]);
  const [errorData, setErrorData] = React.useState({});
  const [isAttribute, setIsAttribute] = React.useState(0);
  const [combination, setCombination] = React.useState([]);
  const [productDetail, setProductDetail] = React.useState([]);
  const [GlobalLoading, setGlobalLoading] = React.useState(false);
  const [attributeImages, setAttributeImages] = React.useState([]);
  const [attributeImagesFile, setAttributeImagesFile] = React.useState([]);
  const [showAttributeModel, setShowAttributeModel] = React.useState(false);
  const [selectedAttributeDetail, setSelectedAttributeDetail] = React.useState(
    []
  );
  const [primaryAttributeLoading, setPrimaryAttributeLoading] =
    React.useState(null);
  const [sectionStatus, setSectionStatus] = React.useState();
  React.useEffect(() => {
    if (status) {
      setSectionStatus(status);
    }
  }, [status]);

  React.useEffect(() => {
    if (!isCategoryChanged) {
      fetchProductDetails();
    }
  }, []);

  const fetchProductDetails = async () => {
    if (productId) {
      localStorage.removeItem("categoryId");
      const { data } = await dispatch(getProductDetailNew(productId));
      if (data.data) {
        setProductState(data.data);
        setProductDetail(data.data);
      }
    }
  };

  const reStructureVariantCombination = async (
    productAttributeVariantDetail = []
  ) => {
    let combination = [];
    productAttributeVariantDetail.forEach((pair) => {
      combination.push([
        JSON.parse(pair.variant_value),
        {
          price: pair.price,
          image: pair.image ? pair.image : "",
        },
      ]);
    });
    return combination;
  };

  const reStructureSelectedAttributes = async (
    attributesList,
    selectedAttributes = []
  ) => {
    let selectedAttributeArray = [];
    let selectedAttributesNameArray = [];

    if (selectedAttributes.length > 0) {
      selectedAttributes.forEach((attribute) => {
        selectedAttributesNameArray.push(attribute.name.toLowerCase());
      });
    }
    let attributeListArray = attributesList.map((attribute) => {
      let attributeName = attribute.name;

      if (selectedAttributesNameArray.includes(attributeName.toLowerCase())) {
        selectedAttributeArray[
          selectedAttributesNameArray.indexOf(attributeName.toLowerCase())
        ] = {
          value: attributeName,
          label: attributeName.replace("_", " "),
        };
      }
      return { value: attributeName, name: attributeName.replace("_", " ") };
    });

    return { attributeListArray, selectedAttributeArray };
  };

  const setProductState = React.useCallback(async (product) => {
    product?.is_variant === null
      ? setAttribute(0)
      : setIsAttribute(product?.is_variant);
    if (product.attributePrimaryIndex !== null) {
      setPrimary({
        isPrimary: true,
        primaryIndex: product.attributePrimaryIndex,
      });
    }
    if (product?.selectedAttributes.length) {
      const reStructureAttributes = await reStructureSelectedAttributes(
        product.attributes,
        product.selectedAttributes
      );
      setAttribute({
        attributeList: product.attributes,
        selectedAttribute: reStructureAttributes?.selectedAttributeArray,
      });
    }
    if (product?.productAttributeVariantDetail.length) {
      const reStructuredCombinations = await reStructureVariantCombination(
        product?.productAttributeVariantDetail
      );
      setCombination(reStructuredCombinations);
    }
  }, []);

  React.useEffect(() => {
    if (productDetail?.productAttributeVariantDetail?.length) {
      handleGetVariants(productDetail.productAttributeVariantDetail);
    }
  }, [productDetail]);

  const variationValidation = (index) => {
    if (!selectedAttributeDetail[index]?.selectedVariationsOptions?.length) {
      return (
        <span className="form-field-error">{errorData.selectedVariation}</span>
      );
    }
  };

  React.useEffect(() => {
    if (combination.length)
      for (let item of combination) {
        if (item[1] && item[1].price && item[1].image) {
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            variantDetail: "",
          }));
        }
      }
  }, [combination]);

  React.useEffect(() => {
    setErrorData((prevErrorData) => ({
      ...prevErrorData,
      selectedAttribute: attribute.selectedAttribute
        ? ""
        : prevErrorData.selectedAttribute,
      createVariant: selectedAttributeDetail.length
        ? ""
        : prevErrorData.createVariant,
      selectedVariation:
        selectedAttributeDetail &&
        selectedAttributeDetail[0]?.selectedVariationsOptions?.length
          ? ""
          : prevErrorData.selectedVariation,
      combination: combination.length ? "" : prevErrorData.combination,
      imageError:
        globalData?.image?.attributeImagesFile.length ||
        primaryAttribute.image.attributeImagesFile.length
          ? ""
          : prevErrorData.imageError,
    }));
  }, [
    attribute.selectedAttribute,
    selectedAttributeDetail,
    selectedAttributeDetail.selectedVariationsOptions,
    combination,
    globalData,
    primaryAttribute,
  ]);

  const validateInfo = () => {
    let isFormValid = true;
    let errorObj = {};
    if (isAttribute && !attribute.selectedAttribute?.length) {
      isFormValid = false;
      errorObj["selectedAttribute"] = `Attribute is required.`;
    }
    if (isAttribute && !selectedAttributeDetail.length) {
      isFormValid = false;
      errorObj["createVariant"] = `Create a variants.`;
    }
    if (
      isAttribute &&
      selectedAttributeDetail &&
      !selectedAttributeDetail[0]?.selectedVariationsOptions?.length
    ) {
      isFormValid = false;
      errorObj["selectedVariation"] = `Variation is required.`;
    }
    if (isAttribute && !combination.length) {
      isFormValid = false;
      errorObj["combination"] = `Create a combinations.`;
    }
    if (combination.length) {
      for (let item of combination) {
        if (!item[1] || !item[1].price) {
          isFormValid = false;
          errorObj[
            "variantDetail"
          ] = `You have missing detail either image or price for variant.`;
        }
      }
    }
    setErrorData(errorObj);
    return isFormValid;
  };

  const fetchAttributes = async (value, key) => {
    if (value) {
      const sellerId = localStorage.getItem("sellerId");
      const attributesList = await dispatch(getAttributeList(sellerId, 0));
      if (attributesList?.data?.data?.length > 0) {
        if (key === "add_attribute") {
          const attributeArray = attribute;
          setAttribute({
            attributeList: attributesList?.data?.data,
            selectedAttribute: attributeArray.selectedAttribute,
          });
        } else {
          setAttribute({
            attributeList: attributesList?.data?.data,
            selectedAttribute: [],
          });
        }
      }
      setIsAttribute(1);
    } else {
      setIsAttribute(0);
    }
  };
  const handleModalSubmit = () => {
    fetchAttributes(1, "add_attribute");
    setShowAttributeModel(false);
  };

  const variationValuesSplitter = (
    variationValues,
    selectedVariationsValues
  ) => {
    let selectedVariationsOptions = [];
    variationValues = variationValues?.split(",");

    const variationValuesList = variationValues?.map((variation) => {
      if (selectedVariationsValues.includes(variation)) {
        selectedVariationsOptions.push({
          value: `${variation}`,
          label: `${variation}`,
        });
      }
      return { value: `${variation}`, label: `${variation}` };
    });

    return { variationValuesList, selectedVariationsOptions };
  };

  const createVariantsOptions = async (
    attributeName,
    selectedVariationsValues = []
  ) => {
    let attributeInfo = {};
    attribute.attributeList.forEach((variant) => {
      if (variant.name === attributeName) {
        attributeInfo = variant;
      }
    });
    const attributeVariationValuesList = variationValuesSplitter(
      attributeInfo.variation_values,
      selectedVariationsValues
    );
    return {
      attributeName,
      attributeVariationValuesList:
        attributeVariationValuesList.variationValuesList,
      selectedVariationsOptions:
        attributeVariationValuesList.selectedVariationsOptions,
    };
  };

  const handleGetVariants = async (selectedVariants) => {
    let selectedAttributeVariants = [...selectedAttributeDetail];
    let attributeVariantObj = {};

    if (selectedVariants?.length > 0) {
      const allKeys = Object.keys(
        JSON.parse(selectedVariants[0].variant_value)
      );

      selectedVariants.forEach((variant) => {
        variant = JSON.parse(variant.variant_value);
        allKeys.forEach((key) => {
          if (!attributeVariantObj[key]) {
            attributeVariantObj[key] = [variant[key]];
          } else {
            if (!attributeVariantObj[key].includes(variant[key])) {
              attributeVariantObj[key].push(variant[key]);
            }
          }
        });
      });
    }

    attribute &&
      attribute.selectedAttribute.length &&
      attribute.selectedAttribute.map(async (item, index) => {
        const createOptions = await createVariantsOptions(
          item.name ? item.name : item.label,
          attributeVariantObj[item.value]
        );
        const tempArray = attribute.attributeList.map((item) => {
          return item.name;
        });
        if (
          tempArray?.includes(attribute.selectedAttribute[index]?.name) ||
          tempArray?.includes(attribute.selectedAttribute[index]?.value)
        ) {
          selectedAttributeVariants.push(createOptions);
        }
        setSelectedAttributeDetail(selectedAttributeVariants);
      });
  };

  const handleDeleteCombination = (index) => {
    const combinationArray = [...combination];
    combinationArray.splice(index, 1);
    setCombination(combinationArray);
  };

  const handleAttributeVariations = (e, index) => {
    const selectedAttributeVariants = [...selectedAttributeDetail];
    if (combination && combination.length) {
      confirmAlert({
        title: "Alert",
        message:
          "If you change variant then you have to create combinations again, all previous combinations will be remove",
        buttons: [
          {
            label: "Continue",
            onClick: () => {
              selectedAttributeVariants[index].selectedVariationsOptions = e
                ? e
                : [];
              setSelectedAttributeDetail(selectedAttributeVariants);
              setCombination([]);
            },
          },
          {
            label: "cancel",
            onClick: () => {
              selectedAttributeVariants[index].selectedVariationsOptions = e
                ? selectedAttributeVariants[index].selectedVariationsOptions
                : [];
              setSelectedAttributeDetail(selectedAttributeVariants);
            },
          },
        ],
      });
    } else {
      selectedAttributeVariants[index].selectedVariationsOptions = e ? e : [];
      setSelectedAttributeDetail(selectedAttributeVariants);
      setCombination([]);
    }
  };

  const cartesian = (args) => {
    var r = [],
      max = args.length - 1;

    function helper(arr, i) {
      for (
        var j = 0, l = args[i].selectedVariationsOptions.length;
        j < l;
        j++
      ) {
        var a = JSON.parse(JSON.stringify(arr)); // clone arr
        a = Object.assign(a, {
          [args[i].attributeName]: args[i].selectedVariationsOptions[j].value,
        });
        if (i === max) {
          r.push([a]);
        } else {
          helper(a, i + 1);
        }
      }
    }

    helper({}, 0);
    return r;
  };

  const createVariantCombination = async () => {
    const selectedAttributeVariants = selectedAttributeDetail;
    const combinations = cartesian(selectedAttributeVariants);
    setCombination(combinations);
  };
  const handleToggle = (e, index) => {
    const { checked } = e.target;
    if (checked) {
      setPrimary({ isPrimary: checked, primaryIndex: index });
    } else {
      setPrimary({ isPrimary: checked, primaryIndex: -1 });
    }
  };

  const handleProductAttribute = (e) => {
    if (combination && combination.length) {
      const prevValues = attribute.selectedAttribute;
      const prevSelectedAttributeDetail = selectedAttributeDetail;
      confirmAlert({
        title: "Alert",
        message:
          "If you change caterogies then you have to create combinations again, all previous values will be remove",
        buttons: [
          {
            label: "Continue",
            onClick: () => {
              setAttribute({ ...attribute, selectedAttribute: e });
              setSelectedAttributeDetail([]);
              setCombination([]);
            },
          },
          {
            label: "cancel",
            onClick: () => {
              setSelectedAttributeDetail(prevSelectedAttributeDetail);
              setAttribute({ ...attribute, selectedAttribute: prevValues });
            },
          },
        ],
      });
    } else {
      setAttribute({ ...attribute, selectedAttribute: e });
      setSelectedAttributeDetail([]);
      setCombination([]);
    }
  };

  const onSelectFile = async (e, key, value) => {
    const file = e.target.files[0];
    if (e.target.files && e.target.files?.length > 0) {
      if (e.target.files[0].size > File_SIZE) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          imageError: "File size should not be more then 10MB.",
        }));
        return null;
      }
      const reader = new FileReader();
      const compressedFile = await imageCompression(file, IMAGE_COMPRESS_OPTION);
      if (!value && key === "global") {
        reader.addEventListener("load", () => {
          setGlobalData((prevData) => ({
            ...prevData,
            image: {
              attributeImages: [reader.result],
              attributeImagesFile: [compressedFile],
            },
          }));
        });
      } else if (value && key === "primary") {
        reader.addEventListener("load", () => {
          setPrimaryAttribute((prevData) => ({
            ...prevData,
            image: {
              ...prevData.image,
              attributeImages: prevData.image.attributeImages.some(
                (item) => item.key === value
              )
                ? prevData.image.attributeImages.map((item) =>
                    item.key === value
                      ? { ...item, image: reader.result }
                      : item
                  )
                : [
                    ...prevData.image.attributeImages,
                    { key: value, image: reader.result },
                  ],
              attributeImagesFile: prevData.image.attributeImagesFile.some(
                (item) => item.key === value
              )
                ? prevData.image.attributeImagesFile.map((item) =>
                    item.key === value
                      ? { ...item, file: compressedFile }
                      : item
                  )
                : [
                    ...prevData.image.attributeImagesFile,
                    { key: value, file: compressedFile },
                  ],
            },
          }));
        });
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePrimaryData = async (label, index) => {
    try {
      setPrimaryAttributeLoading(index);

      // Get the image file and price related to the label
      const priceItem = primaryAttribute.price.find(
        (item) => item.key === label
      );
      const imageFileItem = primaryAttribute.image.attributeImagesFile.find(
        (file) => file.key === label
      );

      let imageUrl = null;

      // If there is an image file, upload it first
      if (imageFileItem) {
        imageUrl = await uploadSelectedImage(
          "images",
          "/attribute_images",
          index,
          false,
          null,
          imageFileItem.file
        );
      }

      // Update the combination based on price and image
      const updatedCombination = combination.map((combinationItem) => {
        const shouldUpdate = Object.values(combinationItem[0]).some(
          (value) => value === label
        );

        if (shouldUpdate) {
          // If there's an image, update it; otherwise, retain the current image
          const updatedImage = imageUrl ? { image: imageUrl } : {};

          // If there's a price, update it; otherwise, retain the current price
          const updatedPrice = priceItem ? { price: priceItem.price } : {};

          // Merge existing item[1] with updated price and image
          return [
            combinationItem[0],
            { ...combinationItem[1], ...updatedImage, ...updatedPrice },
          ];
        }
        return combinationItem;
      });

      // Update combination once after processing both price and image
      setCombination(updatedCombination);
    } catch (error) {
      console.error(error);
    } finally {
      setPrimaryAttributeLoading(null); // Reset loading state after all tasks are complete
    }
  };

  const handleGlobalData = async () => {
    let imageUrl;
    if (globalData) {
      setGlobalLoading(true);
      if (globalData && globalData?.image?.attributeImagesFile?.length) {
        imageUrl = await uploadSelectedImage(
          "images",
          "/attribute_images",
          0,
          false,
          null,
          globalData.image?.attributeImagesFile[0]
        );
      }
      const newCombination = combination.map((item) => {
        return [
          item[0], // keep the first element as is
          { image: imageUrl, price: globalData.price }, // set the new object
        ];
      });
      setCombination(newCombination);
      setGlobalLoading(false);
    }
  };

  const uploadAttributeImages = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (attributeImagesFile?.length) {
          const newCombination = [...combination];
          // Use Promise.all to wait for all image uploads
          const uploadPromises = attributeImagesFile.map(
            async (item, index) => {
              if (item?.file) {
                const imageUrl = await uploadSelectedImage(
                  "images",
                  "/attribute_images",
                  index,
                  false,
                  null,
                  item?.file
                );
                newCombination[index] = [...newCombination[index]];
                newCombination[index][1] = {
                  ...newCombination[index][1],
                  image: imageUrl,
                };
              }
            }
          );
          setCombination(newCombination);
          // Wait for all uploads to finish
          await Promise.all(uploadPromises);

          dispatch(
            addProductAttributes({
              isAttribute,
              productId,
              combination: newCombination,
              selectedAttributeDetail,
              primaryIndex: primary.isPrimary ? primary.primaryIndex : null,
            })
          )
            .then((result) => {
              setLoading(false);
              if (result.status === 201) {
                navigate("/marketing-content", {
                  state: {
                    isCategoryChanged,
                    productId: productId,
                    categoryId: categoryId,
                  },
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error during attribute submission:", error);
            });

          console.log("All image uploads completed.");
          resolve();
        } else {
          resolve();
        }
      } catch (error) {
        console.error("Error during image upload:", error);
        reject(error);
      }
    });
  };

  const handleSubmit = async () => {
    if (isAttribute) {
      if (validateInfo()) {
        setLoading(true);
        if (attributeImagesFile?.length) {
          await uploadAttributeImages();
        } else {
          dispatch(
            addProductAttributes({
              isAttribute,
              productId,
              combination,
              selectedAttributeDetail,
              primaryIndex: primary.isPrimary ? primary.primaryIndex : null,
            })
          )
            .then((result) => {
              setLoading(false);
              const updatedSectionStatus = {
                ...sectionStatus,
                productAttribute: true,
              };
              setSectionStatus(updatedSectionStatus);
              if (result.status === 201) {
                navigate("/marketing-content", {
                  state: {
                    isCategoryChanged,
                    productId: productId,
                    categoryId: categoryId,
                    sectionStatus: updatedSectionStatus,
                  },
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error during attribute submission:", error);
            });
        }
      }
    } else {
      const updatedSectionStatus = {
        ...sectionStatus,
        productAttribute: true,
      };
      setSectionStatus(updatedSectionStatus);
      navigate("/marketing-content", {
        state: {
          isCategoryChanged,
          productId: productId,
          categoryId: categoryId,
          sectionStatus: updatedSectionStatus,
        },
      });
      const state = {
        isAttribute,
        productId,
      };
      await dispatch(addProductAttributes(state));
    }
  };
  const uploadSelectedImage = async (
    type,
    fileUploadPathEndPoint,
    index,
    isPrivate,
    subCatIndex,
    file
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          const res = await dispatch(
            uploadProductFiles(
              "/api/v1/seller/product/files/upload",
              null,
              null,
              type,
              index,
              {
                productId: productId,
                file: file,
                fileUploadPathEndPoint: fileUploadPathEndPoint,
                isPrivate: isPrivate,
                fileName: "",
              }
            )
          );

          if (res && res.imageUrl) {
            resolve(res.imageUrl); // Resolve with the imageUrl
          } else {
            reject("Image URL not found in response.");
          }
        } else {
          reject("File is undefined.");
        }
      } catch (error) {
        reject(error); // Reject the promise on error
      }
    });
  };

  const handleUploadImage = async (e, index) => {
    const file = e.target.files[0];
    if (e.target.files[0].size > File_SIZE) {
      setErrorData((prevErrorData) => ({
        ...prevErrorData,
        manualUploadImageError: "File size should not be more then 10MB.",
      }));
      return null;
    }
    setErrorData((prevErrorData) => ({
      ...prevErrorData,
      manualUploadImageError: "",
    }));
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setAttributeImages((prevState) => ({
        ...prevState,
        [index]: fileReader.result,
      }));
    };
    fileReader.readAsDataURL(file);
    const compressedFile = await imageCompression(file, IMAGE_COMPRESS_OPTION);
    setAttributeImagesFile((prev) => {
      const newState = [...prev];
      newState[index] = { file: compressedFile };
      return newState;
    });
  };
  const handleAttributePrice = (e, index) => {
    const variantCombination = [...combination];
    variantCombination[index][1] = {
      ...variantCombination[index][1],
      price: e.target.value > 0 ? e.target.value : 0,
    };
    setCombination(variantCombination);
  };

  const handlePrimaryPrice = (e, label) => {
    const updatedPrice = e.target.value;
    setPrimaryAttribute((prevData) => {
      const existingPriceIndex = prevData.price.findIndex(
        (p) => p.key === label
      );
      let newPriceArray = [...prevData.price];

      if (existingPriceIndex !== -1) {
        // Update existing price for the key
        newPriceArray[existingPriceIndex].price = updatedPrice;
      } else {
        // Add new price entry for the key
        newPriceArray.push({
          key: label,
          price: updatedPrice,
        });
      }
      return {
        ...prevData,
        price: newPriceArray,
      };
    });
  };

  return (
    <>
      <div className="add-product-page">
        <div className="page-heading">
          <button
            onClick={() =>
              navigate("/product-configuration", {
                state: { sectionStatus: sectionStatus, categoryId, productId },
              })
            }
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Add Product</h2>
        </div>
        <div className="white-card">
          <ProductHeader
            state={{
              productId: productId,
              categoryId: categoryId,
              sectionStatus: status,
            }}
          />
          <div className="select-category-section">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="form-inner-hrading mb-2">
                  {productDetail.type === "shopify" ||
                  productDetail.type === "woocommerce" ? (
                    <div className="primary-note input-note mb-4">
                      <p>
                        <b>Note:</b> You cannot update attributes for
                        Shopify/Woocommerce products from Subsciety.
                      </p>
                    </div>
                  ) : null}
                  <div className="name">
                    <h4>Do You Want To Set Attributes For This Product?</h4>
                    <div className="tooltip-box">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Add product variations to give customers more
                            choices.
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline-radio">
                    <RadioBox
                      label="Yes"
                      value={1}
                      checked={isAttribute === 1}
                      onChange={() => fetchAttributes(1)}
                      disabled={
                        productDetail.type === "shopify" ||
                        productDetail.type === "woocommerce"
                      }
                    />
                    <RadioBox
                      label="No"
                      value={0}
                      checked={isAttribute === 0}
                      onChange={() => fetchAttributes(0)}
                      disabled={
                        productDetail.type === "shopify" ||
                        productDetail.type === "woocommerce"
                      }
                    />
                  </div>
                </div>
              </div>

              {isAttribute ? (
                <>
                  <div className="col-md-12 col-lg-12">
                    <div className="form-group">
                      <div className="d-flex g-2 justify-content-between">
                        <div className="d-flex g-2">
                          <label>
                            Product Attributes<sup>*</sup>
                          </label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                  Select attributes for your product or create
                                  new attribute using "add new attribute" on the
                                  right.
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="link-button primary-btn"
                          onClick={() => setShowAttributeModel(true)}
                          disabled={
                            productDetail.type === "shopify" ||
                            productDetail.type === "woocommerce"
                          }
                        >
                          {" "}
                          <AddIcon />
                          Add New Attribute
                        </button>
                      </div>

                      <SelectBox
                        required
                        isMulti
                        parentClass="mb-2"
                        options={attribute.attributeList?.map((i) => {
                          return { ...i, label: i.name, value: i.id };
                        })}
                        value={attribute.selectedAttribute}
                        error={errorData?.selectedAttribute}
                        onChange={(e) => handleProductAttribute(e)}
                        isDisabled={
                            productDetail.type === "shopify" ||
                            productDetail.type === "woocommerce"
                          }
                      />
                      {attribute?.selectedAttribute?.length > 0 && (
                        <CommonButton
                          value="Get Variants"
                          onClick={() => handleGetVariants()}
                          buttonClass="theme-button primary-outline-btn radius-btn mb-2"
                          disabled={
                            productDetail.type === "shopify" ||
                            productDetail.type === "woocommerce"
                          }
                        />
                      )}
                      {attribute.selectedAttribute?.length &&
                      !selectedAttributeDetail?.length ? (
                        <span className="form-field-error">
                          {errorData?.createVariant
                            ? errorData?.createVariant
                            : ""}
                        </span>
                      ) : null}
                      {attribute.selectedAttribute &&
                        attribute.selectedAttribute?.length > 0 && selectedAttributeDetail.length ?(
                          <div className="input-note">
                            <p>
                              <b>Note:</b> Set the primary attribute to define
                              your product variants. Only one attribute can be
                              primary at a time.
                            </p>
                          </div>
                        ): null}
                    </div>
                  </div>
                </>
              ) : null}
              {isAttribute &&
              attribute.selectedAttribute?.length &&
              selectedAttributeDetail
                ? selectedAttributeDetail.map((value, index) => {
                    return (
                      <div key={index} className="col-md-12 col-lg-12 mb-2">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="d-flex g-2 justify-content-between">
                                <label>
                                  {value?.attributeName}
                                  <sup>*</sup>
                                </label>
                                <div className="switch-main">
                                  <p>Make Primary</p>
                                  <label class="switch switch-sm">
                                    <input
                                      type="checkbox"
                                      checked={index === primary?.primaryIndex}
                                      onChange={(e) => handleToggle(e, index)}
                                      disabled={
                                        productDetail.type === "shopify" ||
                                        productDetail.type === "woocommerce"
                                      }
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <SelectBox
                                isMulti
                                required
                                parentClass="mb-2"
                                value={value.selectedVariationsOptions}
                                options={value.attributeVariationValuesList}
                                onChange={(e) =>
                                  handleAttributeVariations(e, index)
                                }
                                isDisabled={
                                  productDetail.type === "shopify" ||
                                  productDetail.type === "woocommerce"
                                }
                              />
                              {variationValidation(index)}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="pt-4">
                              <CommonButton
                                value="Select All Variants"
                                buttonClass="theme-button primary-outline-btn radius-btn mt-2"
                                onClick={() =>
                                  handleAttributeVariations(
                                    value.attributeVariationValuesList,
                                    index
                                  )
                                }
                                disabled={
                                  productDetail.type === "shopify" ||
                                  productDetail.type === "woocommerce"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
              {isAttribute &&
              attribute.selectedAttribute?.length &&
              selectedAttributeDetail?.length ? (
                <div className="col-md-12 col-lg-12 mb-2">
                  <div>
                    <CommonButton
                      value="Create Variant Combinations"
                      onClick={createVariantCombination}
                      buttonClass="theme-button primary-outline-btn radius-btn mb-2"
                      disabled={
                        productDetail.type === "shopify" ||
                        productDetail.type === "woocommerce"
                      }
                    />
                    {!combination.length ? (
                      <span className="form-field-error">
                        {errorData?.combination ? errorData?.combination : ""}
                      </span>
                    ) : null}
                  </div>
                  <div className="input-note">
                    { combination.length > 0 ? 
                    !primary.isPrimary ? (
                      <p>
                        <b>Note:</b> Upload the default image for all variants
                        using Global details or upload images for each variant.
                      </p>
                    ) : (
                      <p>
                        <b>Note:</b> When you choose one attribute as Primary,
                        you can set an image and price that will apply to all
                        the combinations. You can also change these values for
                        specific combination.
                      </p>
                    ): null}
                  </div>
                </div>
              ) : null}

              {isAttribute &&
              primary.isPrimary &&
              primary.primaryIndex !== -1 &&
              selectedAttributeDetail.length ? (
                <div className="col-md-12 col-lg-12">
                  <div className="form-inner-hrading mb-2">
                    <div className="name">
                      <h4>Primary Attributes Details</h4>
                    </div>
                  </div>

                  <div className="theme-table">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <td>Product Image</td>
                            <td>Primary Attribute</td>
                            <td>Price</td>
                            <td>Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAttributeDetail[
                            primary.primaryIndex
                          ]?.selectedVariationsOptions.map((item, index) => {
                            const primaryPrice = primaryAttribute.price.find(
                              (value) => value.key === item.label
                            )?.price;
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="image-with-uploader">
                                    <div className="placeholder-img">
                                      <img
                                        src={
                                          primaryAttribute.image.attributeImages
                                            .length
                                            ? primaryAttribute.image.attributeImages
                                                .filter(
                                                  (value) =>
                                                    item.label === value.key
                                                ) // Filter for matching key
                                                .map(
                                                  (value) => value.image
                                                )[0] || PlaceholderImage // Get the first match or the placeholder
                                            : PlaceholderImage
                                        }
                                        alt="placeholder-image"
                                      />
                                    </div>
                                    <div className="upload-link">
                                      Upload Image
                                      <input
                                        id="file"
                                        type="file"
                                        accept="image/*"
                                        className="inputfile"
                                        onChange={(e) =>
                                          onSelectFile(e, "primary", item.label)
                                        }
                                        data-multiple-caption="{count} files selected"
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>{item.label}</td>

                                <td>
                                  <InputBox
                                    type="number"
                                    value={primaryPrice}
                                    placeholder="Enter Variant Price"
                                    parentClass="form-group mb-0 variant-input"
                                    onChange={(e) =>
                                      handlePrimaryPrice(e, item.label)
                                    }
                                  />
                                </td>
                                <td>
                                  <div>
                                    <CommonButton
                                      value="Apply"
                                      loading={
                                        primaryAttributeLoading === index
                                      }
                                      disabled={
                                        primaryAttributeLoading !== null
                                      }
                                      onClick={() =>
                                        handlePrimaryData(item.label, index)
                                      }
                                      buttonClass="theme-button primary-outline-btn radius-btn"
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <span className="form-field-error">
                        {errorData?.imageError ? errorData?.imageError : ""}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
              {isAttribute &&
              !primary.isPrimary &&
              attribute.selectedAttribute?.length &&
              selectedAttributeDetail?.length &&
              combination?.length ? (
                <>
                  <div className="col-md-12 col-lg-12">
                    <div className="form-inner-hrading mb-2">
                      <div className="name">
                        <h4>Global Details</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="form-group">
                      <div className="d-flex g-2">
                        <label>Upload Image</label>
                        <div className="tooltip-box pl-2">
                          <div class="tooltip-custom">
                            <PrimaryInfoIcon />
                            <div class="together-tooltip">
                              <p>
                                Upload the default image for all variants..
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="upload-box">
                        <input
                          id="file"
                          type="file"
                          accept="image/*"
                          className="inputfile"
                          onChange={(e) => onSelectFile(e, "global")}
                          data-multiple-caption="{count} files selected"
                          disabled={
                            productDetail.type === "shopify" ||
                            productDetail.type === "woocommerce"
                          }
                        />
                        <div className="box-content">
                          {globalData &&
                          globalData.image &&
                          globalData.image.attributeImages.length ? (
                            <img
                              alt=""
                              className="uploaded-image"
                              src={globalData.image?.attributeImages}
                            />
                          ) : null}
                          <ImageIcon />
                          <p>Upload Image</p>
                          <span>
                            (upload .jpeg, .jpg or .png - max file size 10 mb)
                          </span>
                        </div>
                      </div>
                      <span className="form-field-error">
                        {errorData?.imageError ? errorData?.imageError : ""}
                      </span>
                    </div>
                  </div>
                </>
              ) : null}

              {isAttribute &&
              !primary.isPrimary &&
              attribute.selectedAttribute?.length &&
              selectedAttributeDetail?.length &&
              combination?.length ? (
                <div className="col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="d-flex g-2">
                          <label>Global Price</label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                  Set the default price for all variants..
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <InputBox
                          type="number"
                          parentClass="mb-0"
                          value={globalData.price}
                          placeholder="Enter global price"
                          onChange={(e) =>
                            setGlobalData({
                              ...globalData,
                              price: e.target.value > 0 ? e.target.value : 0,
                            })
                          }
                          disabled={
                            productDetail.type === "shopify" ||
                            productDetail.type === "woocommerce"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className=""
                        style={{
                          paddingTop: "35px",
                          paddingBottom: "20px",
                        }}
                      >
                        <CommonButton
                          value="Apply"
                          loading={GlobalLoading}
                          onClick={() => handleGlobalData()}
                          buttonClass="theme-button primary-outline-btn radius-btn"
                          disabled={GlobalLoading || productDetail.type === "shopify" || productDetail.type === "woocommerce"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {isAttribute &&
              attribute.selectedAttribute?.length &&
              selectedAttributeDetail?.length &&
              combination.length ? (
                <div className="col-md-12 col-lg-12">
                  {primary.isPrimary && primary.primaryIndex !== -1 ? (
                    <div className="form-inner-hrading mb-2">
                      <div className="name">
                        <h4>Variants Details</h4>
                      </div>
                    </div>
                  ) : null}
                  <span className="form-field-error">
                    {errorData?.manualUploadImageError
                      ? errorData?.manualUploadImageError
                      : ""}
                  </span>
                  <div className="theme-table">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <td>Variant’s Image</td>
                            <td>Variant Combination</td>
                            <td>Price</td>
                            <td>Sale Price</td>
                            <td>Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {combination.length &&
                            combination.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="image-with-uploader">
                                      <div className="placeholder-img">
                                        <img
                                          src={
                                            attributeImages[index]
                                              ? attributeImages[index]
                                              : item[1]?.image
                                              ? item[1]?.image
                                              : PlaceholderImage
                                          }
                                          alt="placeholder-image"
                                        />
                                      </div>
                                      <div className="upload-link">
                                        Upload Image
                                        <input
                                          type="file"
                                          accept="image/*"
                                          name="attributeImage"
                                          className="inputfile"
                                          onChange={(e) =>
                                            handleUploadImage(e, index)
                                          }
                                          disabled={
                                            productDetail.type === "shopify" ||
                                            productDetail.type === "woocommerce"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>{Object.values(item[0]).join(", ")}</td>
                                  <td>
                                    <InputBox
                                      type="number"
                                      value={item[1]?.price}
                                      placeholder="Enter Variant Price"
                                      parentClass="form-group mb-0 variant-input"
                                      onChange={(e) =>
                                        handleAttributePrice(e, index)
                                      }
                                      disabled={
                                        productDetail.type === "shopify" ||
                                        productDetail.type === "woocommerce"
                                      }
                                    />
                                  </td>
                                  <td>
                                    {productDetail.is_sale === 1 ? (
                                      productDetail.price_type === 2 ? (
                                        item[1] && item[1].price ? (
                                          <div>
                                            {`${parseFloat(
                                              (
                                                item[1].price *
                                                (1 -
                                                   ( Math.round(
                                                    100 * (1 - productDetail.sale_price / productDetail.price)
                                                  ) /
                                                    100))
                                              ).toFixed(2)
                                            )}`}
                                          </div>
                                        ) : (
                                          "-"
                                        )
                                      ) : productDetail.price_type === 1 ? (
                                        item[1] && item[1].price ? (
                                          <div>
                                            {`${parseFloat(
                                              (
                                                item[1].price -
                                                productDetail.sale_price
                                              ).toFixed(2)
                                            )}`}
                                          </div>
                                        ) : (
                                          "-"
                                        )
                                      ) : (
                                        "-"
                                      )
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    <div>
                                      <button
                                        type="button"
                                        className="icon-button dark-btn"
                                        onClick={() =>
                                          handleDeleteCombination(index)
                                        }
                                        disabled={
                                          productDetail.type === "shopify" ||
                                          productDetail.type === "woocommerce"
                                        }
                                      >
                                        <DeleteIcon />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {attribute.selectedAttribute?.length &&
                      combination.length ? (
                        <span className="form-field-error">
                          {errorData?.variantDetail
                            ? errorData?.variantDetail
                            : ""}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <AddAttribute
            showAttributeModel={showAttributeModel}
            handleClose={() => setShowAttributeModel(false)}
            onSubmit={handleModalSubmit}
          />

          <div className="action-btn">
            <CommonButton
              value="Continue"
              loading={loading}
              disabled={loading}
              onClick={() => handleSubmit()}
              buttonClass="theme-button primary-btn radius-btn"
            />
            <CommonButton
              value="Cancel"
              onClick={() => navigate("/product-list")}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductAttributes;

import React from "react";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import imageCompression from "browser-image-compression";

import { BackIcon } from "../../Components/svgElements/BackIcon";
import { FileIcon } from "../../Components/svgElements/FileIcon";
import { ImageIcon } from "../../Components/svgElements/ImageIcon";
import { AudioIcon } from "../../Components/svgElements/AudioIcon";
import { VideoIcon } from "../../Components/svgElements/VideoIcon";
import { DeleteIcon } from "../../Components/svgElements/DeleteIcon";
import { PrimaryInfoIcon } from "../../Components/svgElements/PrimaryInfoIcon";

import InputBox from "../../Components/FormElements/InputBox";
import CommonButton from "../../Components/FormElements/CommonButton";

import PlaceholderImage from "../../assets/images-2/dummyUser.png";

import ProductHeader from "./ProductHeader";

import { FILE_EXTENSIONS, File_SIZE, IMAGE_COMPRESS_OPTION, VIDEO_SIZE } from "../../Constant/Constant";
import { uploadProductFiles } from "../../Redux/Actions/fileUploadAction";
import {
  getProductDetailNew,
  updateProductFiles,
} from "../../Redux/Actions/productAction";

import "./AddProduct.scss";

const SubscriptionContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productId = location.state.productId
  const categoryId = location.state.categoryId
  const status = location.state.sectionStatus;
  const isCategoryChanged = location.state.isCategoryChanged;
  
  const [content, setContent] = React.useState([
    {
      title: null,
      video: [],
      image: [],
      audio: [],
      files: [],
    },
  ]);
  const [loading, setLoading] = React.useState(false);
  const [errorData, setErrorData] = React.useState({});
  const [sectionStatus, setSectionStatus] = React.useState();

  React.useEffect(() => {
    if (status) {
      setSectionStatus(status);
    }
  }, [status]);

  React.useEffect(() => {
    if(!isCategoryChanged){
      fetchProductDetails();
    }
  }, []);

  const setProductState = React.useCallback(async (product) => {
    let updatedContent = [...content];
    if (product?.access_cat_titles?.length > 0) {
      const parsedTitles = JSON.parse(product?.access_cat_titles);
      setContent((prevContent) => {
        // Ensure content array has enough objects to match the parsed titles and images
        for (let i = 0; i < parsedTitles.length; i++) {
          // If the index doesn't exist yet, add a new object
          if (!updatedContent[i]) {
            updatedContent.push({
              title: null,
              video: [],
              image: [],
              audio: [],
              files: [],
            });
          }
          updatedContent[i].title = parsedTitles[i][i];
        }
        return updatedContent;
      });
    }
    if (product?.access_cat_images?.length > 0) {
      const parsedImages = JSON.parse(product?.access_cat_images);
      setContent((prevContent) => {
        parsedImages &&
          parsedImages.forEach((item) => {
            const key = Object.keys(item)[0];
            const urls = item[key]?.split(",");
            urls?.length &&
              urls.forEach((url) => {
                const fileName = url?.split("fileName=")[1];
                updatedContent[key].image.push({
                  imageBase64: url,
                  fileName,
                });
              });
          });
        return updatedContent;
      });
    }
    if (product?.access_cat_videos?.length > 0) {
      const parsedVideos = JSON.parse(product?.access_cat_videos);
      setContent((prevContent) => {
        parsedVideos &&
          parsedVideos.forEach((item) => {
            const key = Object.keys(item)[0];
            const urls = item[key]?.split(",");
            urls.length &&
              urls.forEach((url) => {
                const fileName = url?.split("fileName=")[1];
                updatedContent[key].video.push({
                  videoBase64: url,
                  fileName,
                });
              });
          });
        return updatedContent;
      });
    }
    if (product?.access_cat_audios?.length > 0) {
      const parsedAudios = JSON.parse(product?.access_cat_audios);
      setContent((prevContent) => {
        parsedAudios &&
          parsedAudios.forEach((item) => {
            const key = Object.keys(item)[0];
            const urls = item[key]?.split(",");
            urls.length &&
              urls.forEach((url) => {
                const fileName = url?.split("fileName=")[1];
                updatedContent[key].audio.push({
                  audioBase64: url,
                  fileName,
                });
              });
          });
        return updatedContent;
      });
    }
    if (product?.access_cat_documents?.length > 0) {
      const parsedDocs = JSON.parse(product?.access_cat_documents);
      setContent((prevContent) => {
        parsedDocs &&
          parsedDocs.forEach((item, index) => {
            const key = Object.keys(item)[0]; // Get the first key
            const urls = item[key]?.split(",");
            urls?.length &&
              urls.forEach((url) => {
                let fileName = url?.split("fileName=")[1];
                if (fileName === "null") {
                  fileName = `File${index + 1}`;
                }
                updatedContent[key].files.push({ docFile: url, fileName });
              });
          });
        return updatedContent;
      });
    }
  }, []);

  const fetchProductDetails = async () => {
    if (productId) {
      localStorage.removeItem("categoryId");
      const { data } = await dispatch(getProductDetailNew(productId));
      if (data.data) {
        setProductState(data.data);
        // setProductDetail(data.data);
      }
    }
  };

  const onSelectImage = async (e, index) => {
    if (!loading) {
      const files = Array.from(e.target.files); // Convert FileList to Array
      if (files.length > 0) {
        for (const file of files) {
          if (file.size > File_SIZE) {
            setErrorData((prevErrorData) => ({
              ...prevErrorData,
              imageError: "File size should not be more than 10MB.",
            }));
            continue;
          }
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            imageError: "",
          }));
          const reader = new FileReader();
          const compressedFile = await imageCompression(file, IMAGE_COMPRESS_OPTION);
          reader.addEventListener("load", () => {
            setContent((prevContent) =>
              prevContent.map((item, i) =>
                i === index
                  ? {
                      ...item,
                      image: [
                        ...item.image,
                        {
                          imageBase64: reader.result,
                          imageFile: compressedFile,
                        },
                      ],
                    }
                  : item
              )
            );
          });
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const onSelectVideo = (e, index) => {
    if (!loading) {
      const files = Array.from(e.target.files); // Convert FileList to Array
      if (files.length > 0) {
        for (const file of files) {
          if (file.size > VIDEO_SIZE) {
            setErrorData((prevErrorData) => ({
              ...prevErrorData,
              videoError: "File size should not be more than 20MB.",
            }));
            continue;
          }
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            videoError: "",
          }));
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setContent((prevContent) =>
              prevContent.map((item, i) =>
                i === index
                  ? {
                      ...item,
                      video: [
                        ...item.video,
                        {
                          videoBase64: reader.result,
                          videoFile: file,
                        },
                      ],
                    }
                  : item
              )
            );
          });
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const onSelectFile = (e, index) => {
    if (!loading) {
      const files = Array.from(e.target.files); // Convert FileList to Array
      if (files.length > 0) {
        for (const file of files) {
          if(!FILE_EXTENSIONS.includes(file.type.split("/")[1])){
            setErrorData((prevErrorData) => ({
              ...prevErrorData,
              fileError: "File type not valid.",
            }));
            continue;
          }
          if (file.size > File_SIZE) {
            setErrorData((prevErrorData) => ({
              ...prevErrorData,
              fileError: "File size should not be more than 10MB.",
            }));
            continue;
          }
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            fileError: "",
          }));
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setContent((prevContent) =>
              prevContent.map((item, i) =>
                i === index
                  ? {
                      ...item,
                      files: [
                        ...item.files,
                        {
                          docName: file.name,
                          docFile: file,
                        },
                      ],
                    }
                  : item
              )
            );
          });
          reader.readAsDataURL(file);
        }
      }
    }
  };
  const onSelectAudio = (e, index) => {
    if (!loading) {
      const files = Array.from(e.target.files); // Convert FileList to Array
      if (files.length > 0) {
        for (const file of files) {
          if (file.size > VIDEO_SIZE) {
            setErrorData((prevErrorData) => ({
              ...prevErrorData,
              audioError: "File size should not be more than 20MB.",
            }));
            continue;
          }
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            audioError: "",
          }));
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setContent((prevContent) =>
              prevContent.map((item, i) =>
                i === index
                  ? {
                      ...item,
                      audio: [
                        ...item.audio,
                        {
                          audioBase64: reader.result,
                          audioFile: file,
                        },
                      ],
                    }
                  : item
              )
            );
          });
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const addAnotherCategory = () => {
    const temp = [...content];
    temp.push({
      title: null,
      video: [],
      image: [],
      audio: [],
      files: [],
    });
    setContent(temp);
  };

  const handleCategoryName = (e, index) => {
    const { value } = e.target;
    setContent((prevContent) =>
      prevContent.map((item, i) =>
        i === index
          ? {
              ...item,
              title: value,
            }
          : item
      )
    );
  };

  const handleRemoveCategory = (index) => {
    const temp = [...content];
    temp.splice(index, 1);
    setContent(temp);
  };

  const uploadSelectedFile = async (
    type,
    fileUploadPathEndPoint,
    index,
    isPrivate,
    j,
    file,
    fileName
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          const res = await dispatch(
            uploadProductFiles(
              "/api/v1/seller/product/files/upload",
              null,
              null,
              type,
              index,
              {
                productId: productId,
                file: file,
                fileUploadPathEndPoint: fileUploadPathEndPoint,
                isPrivate: isPrivate,
                j,
                fileName,
              }
            )
          );
          if (res && res.imageUrl) {
            resolve(res.imageUrl);
          } else {
            reject("Image URL not found in response.");
          }
        } else {
          reject("File is undefined.");
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const removeLastComma = (str) => {
    if (str.endsWith(",")) {
      return str.slice(0, -1);
    }
    return str;
  };

  const handleSubmit = async () => {
    setLoading(true);
    let accessCategoryDocGalleryUrl = [];
    let accessCategoryAudioGalleryUrl = [];
    let accessCategoryImageGalleryUrl = [];
    let accessCategoryVideoGalleryUrl = [];
    let accessCategorySubcategoryTitle = [];

    return new Promise(async (resolve, reject) => {
      const temp = [...content];
      if (temp.length > 0) {
        try {
          const allUploads = temp.flatMap((item, i) => {
            let obj = {};
            obj[i] = item.title;
            accessCategorySubcategoryTitle.push(obj);
            const uploads = [];
            if (item.image.length > 0) {
              let obj = {};
              let str = "";
              const imageUploads = item.image.map(async (img, j) => {
                if (!item.image[j].imageBase64.includes("base64")) {
                  const urls = item.image[j].imageBase64?.split(",");
                  urls.length &&
                    urls.forEach((url) => {
                      str = str + url + ",";
                      obj[i] = removeLastComma(str);
                      if (j === item.image.length - 1) {
                        accessCategoryImageGalleryUrl.push(obj);
                      }
                    });
                }
                let imageUrl;
                if (img.imageFile) {
                  imageUrl = await uploadSelectedFile(
                    "images",
                    "/access/image",
                    i,
                    true,
                    j,
                    img.imageFile,
                    img.fileName
                  );
                }
                if (imageUrl) {
                  str = str + imageUrl + ",";
                  obj[i] = removeLastComma(str);
                  if (j === item.image.length - 1) {
                    accessCategoryImageGalleryUrl.push(obj);
                  }

                  setContent((prevContent) =>
                    prevContent.map((item, index) =>
                      index === i
                        ? {
                            ...item,
                            image: item.image.map((imageItem, imageIndex) =>
                              imageIndex === j
                                ? {
                                    imageBase64: imageUrl,
                                  }
                                : imageItem
                            ),
                          }
                        : item
                    )
                  );
                }
              });
              uploads.push(...imageUploads);
            }

            if (item.video.length > 0) {
              let obj = {};
              let str = "";
              const videoUploads = item.video.map(async (video, j) => {
                if (!item.video[j].videoBase64.includes("base64")) {
                  const urls = item.video[j].videoBase64?.split(",");
                  urls.length &&
                    urls.forEach((url) => {
                      str = str + url + ",";
                      obj[i] = removeLastComma(str);
                      if (j === item.video.length - 1) {
                        accessCategoryVideoGalleryUrl.push(obj);
                      }
                    });
                }
                let videoUrl;
                if (video.videoFile) {
                  videoUrl = await uploadSelectedFile(
                    "videos",
                    "/access/video",
                    i,
                    true,
                    j,
                    video.videoFile
                  );
                }
                if (videoUrl) {
                  str = str + videoUrl + ",";
                  obj[i] = removeLastComma(str);
                  if (j === item.video.length - 1) {
                    accessCategoryVideoGalleryUrl.push(obj);
                  }

                  setContent((prevContent) =>
                    prevContent.map((item, index) =>
                      index === i
                        ? {
                            ...item,
                            video: item.video.map((videoItem, videoIndex) =>
                              videoIndex === j
                                ? {
                                    videoBase64: videoUrl,
                                  }
                                : videoItem
                            ),
                          }
                        : item
                    )
                  );
                }
              });
              uploads.push(...videoUploads);
            }
            if (item.audio.length > 0) {
              let obj = {};
              let str = "";
              const audioUploads = item.audio.map(async (audio, j) => {
                if (!item.audio[j].audioBase64.includes("base64")) {
                  const urls = item.audio[j].audioBase64?.split(",");
                  urls.length &&
                    urls.forEach((url) => {
                      str = str + url + ",";
                      obj[i] = removeLastComma(str);
                      if (j === item.audio.length - 1) {
                        accessCategoryAudioGalleryUrl.push(obj);
                      }
                    });
                }
                let audioUrl;
                if (audio.audioFile) {
                  audioUrl = await uploadSelectedFile(
                    "audios",
                    "/access/audio",
                    i,
                    true,
                    j,
                    audio.audioFile
                  );
                }
                if (audioUrl) {
                  str = str + audioUrl + ",";
                  obj[i] = removeLastComma(str);
                  if (j === item.audio.length - 1) {
                    accessCategoryAudioGalleryUrl.push(obj);
                  }

                  setContent((prevContent) =>
                    prevContent.map((item, index) =>
                      index === i
                        ? {
                            ...item,
                            audio: item.audio.map((audioItem, audioIndex) =>
                              audioIndex === j
                                ? {
                                    audioBase64: audioUrl,
                                  }
                                : audioItem
                            ),
                          }
                        : item
                    )
                  );
                }
              });
              uploads.push(...audioUploads);
            }
            if (item.files.length > 0) {
              let obj = {};
              let str = "";
              const fileUploads = item.files.map(async (file, j) => {
                if (!file.docName) {
                  const urls = item.files[j].docFile?.split(",");
                  urls.length &&
                    urls.forEach((url) => {
                      str = str + url + ",";
                      obj[i] = removeLastComma(str);
                      if (j === item.files.length - 1) {
                        accessCategoryDocGalleryUrl.push(obj);
                      }
                    });
                }
                let fileUrl;
                if (file.docName) {
                  fileUrl = await uploadSelectedFile(
                    "doc",
                    "/access/doc",
                    i,
                    true,
                    j,
                    file.docFile
                  );
                }
                if (fileUrl) {
                  str = str + fileUrl + ",";
                  obj[i] = removeLastComma(str);
                  if (j === item.files.length - 1) {
                    accessCategoryDocGalleryUrl.push(obj);
                  }

                  setContent((prevContent) =>
                    prevContent.map((item, index) =>
                      index === i
                        ? {
                            ...item,
                            files: item.files.map((fileItem, fileIndex) =>
                              fileIndex === j
                                ? {
                                    docFile: fileUrl,
                                  }
                                : fileItem
                            ),
                          }
                        : item
                    )
                  );
                }
              });
              uploads.push(...fileUploads);
            }
            return uploads;
          });
          const results = await Promise.allSettled(allUploads);
          resolve(results);
          const response = await dispatch(
            updateProductFiles({
              productId: productId,
              accessCategorySubcategoryTitle: JSON.stringify(
                accessCategorySubcategoryTitle
              ),
              accessCategoryImageGalleryUrl: JSON.stringify(
                accessCategoryImageGalleryUrl
              ),
              accessCategoryVideoGalleryUrl: JSON.stringify(
                accessCategoryVideoGalleryUrl
              ),
              accessCategoryAudioGalleryUrl: JSON.stringify(
                accessCategoryAudioGalleryUrl
              ),
              accessCategoryDocGalleryUrl: JSON.stringify(
                accessCategoryDocGalleryUrl
              ),
            })
          );
          if (response.data.isUpdated) {
            setLoading(false);
            if (content[0]?.title?.length || content[0]?.video?.length || content[0]?.audio?.length || content[0]?.files?.length || content[0]?.image?.length)
              {const updatedSectionStatus = {
                ...sectionStatus,
                subscriptionContent: true,
              };
            setSectionStatus(updatedSectionStatus);
            navigate("/marketing-content", {
              state: {isCategoryChanged, productId: productId, categoryId: categoryId,sectionStatus:updatedSectionStatus },
            });
            } else {
              setLoading(false);
              const updatedSectionStatus = {
                ...sectionStatus,
                subscriptionContent: false,
              };
              setSectionStatus(updatedSectionStatus);
              navigate("/marketing-content", {
                state: { isCategoryChanged, productId: productId, categoryId: categoryId, sectionStatus: updatedSectionStatus },
              });
            }
          }
        } catch (error) {
          console.log(error);
          reject(error);
        }
      } else {
        setLoading(false);
        const updatedSectionStatus = {
          ...sectionStatus,
          subscriptionContent: true,
        };
        setSectionStatus(updatedSectionStatus);
        resolve([]);
        navigate("/marketing-content", {
          state: { isCategoryChanged,productId: productId, categoryId: categoryId,sectionStatus:updatedSectionStatus },
        });
      }
    });
  };

  const handleFileName = (e, pIndex, childIndex, key) => {
    const { value } = e.target;
    const temp = [...content];
    if (key === "image") {
      temp[pIndex].image[childIndex]["fileName"] = value;
      setContent(temp);
    } else if (key === "video") {
      temp[pIndex].video[childIndex]["fileName"] = value;
      setContent(temp);
    } else if (key === "file") {
      temp[pIndex].files[childIndex]["fileName"] = value;
      setContent(temp);
    } else if (key === "audio") {
      temp[pIndex].audio[childIndex]["fileName"] = value;
      setContent(temp);
    }
  };

  const handleRemove = (pIndex, childIndex, key) => {
    const temp = [...content];
    if (key === "image") {
      temp[pIndex].image.splice(childIndex, 1);
    } else if (key === "video") {
      temp[pIndex].video.splice(childIndex, 1);
    } else if (key === "audio") {
      temp[pIndex].audio.splice(childIndex, 1);
    } else if (key === "file") {
      temp[pIndex].files.splice(childIndex, 1);
    }
    setContent(temp);
  };

  return (
    <>
      <div className="add-product-page">
        <div className="page-heading">
          <button
            onClick={() =>
              navigate("/product-configuration-session", {
                state: { sectionStatus: sectionStatus, categoryId, productId, },
              })
            }
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Add Product</h2>
        </div>
        <div className="white-card">
          <ProductHeader
            state={{
              productId: productId,
              categoryId: categoryId,
              sectionStatus: sectionStatus,
            }}
          />
          <div className="select-category-section">
            <div className="primary-note input-note mb-4">
              <p>
                <b>Note:</b> Content uploaded on this page will only be shown to
                the active subscribers.
              </p>
            </div>
            {content &&
              content.map((item, index) => {
                return (
                  <div key={index} className="subscription-category-box">
                    <div className="form-group">
                      <div className="d-flex g-2 justify-content-between">
                        <div className="d-flex g-2">
                          <label>Category Title</label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                Create And Organize Multiple Categories To Streamline Your Content, Making It Easy To Access And Manage..
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {content.length > 1 ? (
                          <button
                            type="button"
                            disabled={loading}
                            className="link-button primary-btn"
                            onClick={() => handleRemoveCategory(index)}
                          >
                            <DeleteIcon /> Remove Category
                          </button>
                        ) : null}
                      </div>
                      <InputBox
                        type="text"
                        parentClass="mb-0"
                        value={item.title}
                        onChange={(e) => handleCategoryName(e, index)}
                        placeholder="Enter category title"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <div className="d-flex g-2">
                            <label>Video Gallery</label>
                          </div>
                          <div>
                          <div className="upload-box">
                            <input
                              multiple
                              id="file"
                              type="file"
                              className="inputfile"
                              accept="video/mp4,video/m4v,video/webm,video/ogg"
                              onChange={(e) => onSelectVideo(e, index)}
                              data-multiple-caption="{count} files selected"
                            />
                            <div className="box-content">
                              <VideoIcon />
                              <p>Upload Video</p>
                              <span>
                                (upload .mp4, .avi or .m4v file - max file size
                                20 mb)
                              </span>
                            </div>
                          </div>
                          <span className="form-field-error">
                            {errorData?.videoError ? errorData?.videoError : ""}
                          </span>
                          </div>
                          <div className="upload-list mt-3">
                            {item.video &&
                              item.video.map((value, vIndex) => {
                                return (
                                  <div className="upload-item" key={vIndex}>
                                    <div className="item-img">
                                      <video width="200" height="150" controls>
                                        <source src={value.videoBase64} />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </div>
                                    <div className="item-action">
                                      {value.videoFile ? (
                                        <InputBox
                                          type="text"
                                          parentClass="mb-2"
                                          value={value.fileName}
                                          placeholder="Enter File Name"
                                          onChange={(e) =>
                                            handleFileName(
                                              e,
                                              index,
                                              vIndex,
                                              "video"
                                            )
                                          }
                                        />
                                      ) : (
                                        value.fileName !== "null" &&
                                        value.fileName
                                      )}
                                      <button
                                        type="button"
                                        disabled={loading}
                                        className="link-button primary-btn"
                                        onClick={() =>
                                          handleRemove(index, vIndex, "video")
                                        }
                                      >
                                        <DeleteIcon /> Remove
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Audio Gallery</label>
                          <div className="mb-2">
                          <div className="upload-box">
                            <input
                              multiple
                              id="file"
                              type="file"
                              className="inputfile"
                              accept="audio/mp3,audio/wav,audio/ogg"
                              onChange={(e) => onSelectAudio(e, index)}
                              data-multiple-caption="{count} files selected"
                            />
                            <div className="box-content">
                              <AudioIcon />
                              <p>Upload Audio</p>
                              <span>
                                (upload .mp3, .aac or .wav file - max file size
                                20 mb)
                              </span>
                            </div>
                          </div>
                          <span className="form-field-error">
                            {errorData?.audioError ? errorData?.audioError : ""}
                          </span>
                          </div>
                          <div className="upload-list">
                            {item.audio &&
                              item.audio.map((value, aIndex) => {
                                return (
                                  <div className="upload-item" key={aIndex}>
                                    <div className="item-img">
                                      <audio width="200" height="150" controls>
                                        <source
                                          src={value.audioBase64}
                                          // type={item.contentType}
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </audio>
                                    </div>
                                    <div className="item-action">
                                      {value.audioFile ? (
                                        <InputBox
                                          type="text"
                                          parentClass="mb-2"
                                          value={value.fileName}
                                          placeholder="Enter File Name"
                                          onChange={(e) =>
                                            handleFileName(
                                              e,
                                              index,
                                              aIndex,
                                              "audio"
                                            )
                                          }
                                        />
                                      ) : (
                                        value.fileName !== "null" &&
                                        value.fileName
                                      )}
                                      <button
                                        type="button"
                                        disabled={loading}
                                        className="link-button primary-btn"
                                        onClick={() =>
                                          handleRemove(index, aIndex, "audio")
                                        }
                                      >
                                        <DeleteIcon /> Remove
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>Image Gallery</label>
                          <div className="upload-box mb-3">
                            <input
                              multiple
                              id="file"
                              type="file"
                              accept="image/*"
                              className="inputfile"
                              onChange={(e) => onSelectImage(e, index)}
                              data-multiple-caption="{count} files selected"
                            />
                            <div className="box-content">
                              <ImageIcon />
                              <p>Upload Image</p>
                              <span>
                                (upload .jpeg, .jpg or .png - max file size 10
                                mb)
                              </span>
                            </div>
                          </div>
                          <span className="form-field-error">
                            {errorData?.imageError ? errorData?.imageError : ""}
                          </span>
                          <div className="upload-list">
                            {item.image &&
                              item.image.map((value, ImgIndex) => {
                                return (
                                  <div className="upload-item" key={ImgIndex}>
                                    <div className="item-img">
                                      <img
                                        src={
                                          value.imageBase64
                                            ? value.imageBase64
                                            : PlaceholderImage
                                        }
                                        alt="PlaceholderImage"
                                      />
                                    </div>
                                    <div className="item-action">
                                      {value.imageFile ? (
                                        <InputBox
                                          type="text"
                                          parentClass="mb-2"
                                          value={value.fileName}
                                          placeholder="Enter File Name"
                                          onChange={(e) =>
                                            handleFileName(
                                              e,
                                              index,
                                              ImgIndex,
                                              "image"
                                            )
                                          }
                                        />
                                      ) : (
                                        value.fileName !== "null" &&
                                        value.fileName
                                      )}
                                      <button
                                        type="button"
                                        disabled={loading}
                                        className="link-button primary-btn"
                                        onClick={() =>
                                          handleRemove(index, ImgIndex, "image")
                                        }
                                      >
                                        <DeleteIcon /> Remove
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-12">
                        <div className="form-group">
                          <label>File Gallery</label>
                          <div className="upload-box">
                            <input
                              multiple
                              id="file"
                              type="file"
                              className="inputfile"
                              onChange={(e) => onSelectFile(e, index)}
                              data-multiple-caption="{count} files selected"
                              accept={FILE_EXTENSIONS.map((item) => "." + item).join(",")}
                            />
                            <div className="box-content">
                              <FileIcon />
                              <p>Upload File</p>
                              <span>
                                (upload .doc or .pdf file - max file size 10 mb)
                              </span>
                            </div>
                          </div>
                          <span className="form-field-error">
                            {errorData?.fileError ? errorData?.fileError : ""}
                          </span>          
                        </div>
                        <div className="upload-list">
                            {item.files &&
                              item.files.map((value, fIndex) => {
                                return (
                                  <div className="upload-item d-block mb-2" key={fIndex}>
                                    <div className="item-img">
                                      <p>{value.docName}</p>
                                    </div>
                                    <div className="item-action">
                                      {value.docName ? (
                                        <InputBox
                                          type="text"
                                          parentClass="mb-2"
                                          value={value.fileName}
                                          placeholder="Enter File Name"
                                          onChange={(e) =>
                                            handleFileName(
                                              e,
                                              index,
                                              fIndex,
                                              "file"
                                            )
                                          }
                                        />
                                      ) : (
                                        <p className="link-pera">
                                        {value.fileName !== "null" &&
                                          value.fileName}
                                          </p>
                                      )}
                                      <button
                                        type="button"
                                        disabled={loading}
                                        className="link-button primary-btn"
                                        onClick={() =>
                                          handleRemove(index, fIndex, "file")
                                        }
                                      >
                                        <DeleteIcon /> Remove
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="mb-3">
            <CommonButton
              disabled={loading}
              value="Add Another Category"
              onClick={() => addAnotherCategory()}
              buttonClass="theme-button primary-outline-btn radius-btn"
            />
          </div>
          <div className="action-btn">
            <CommonButton
              value="Continue"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
              buttonClass="theme-button primary-btn radius-btn"
            />
            <CommonButton
              value="Cancel"
              disabled={loading}
              onClick={() => navigate("/product-list")}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionContent;

export const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const WEEKLY = "weekly";
export const MONTHLY = "monthly";
export const ANNUAL = "annual";
export const BI_WEEKLY = "bi_weekly";
export const ONE_TIME = "one_time";
export const GROUP = "group";
export const ONE_ON_ONE = "one_on_one";

export const weekDayValue = {
  Sun: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
}

export const PRODUCT_CATEGORIES =  [
  { name: "access", value: 3 },
  { name: "replenish", value: 101 },
  { name: "customize",value: 119 },
];

export const DISCOUNT_TYPE = [
  {label: "Percentage", value: 2},
  {label: "Fixed", value: 1}
]

export const PRODUCT_CONFIGURATION = {
  PRODUCT_HEIGHT: "productHeight",
  PRODUCT_WIDTH: "productWidth",
  PRODUCT_LENGTH: "productLength",
  PRODUCT_WEIGHT: "productWeight",
  PRODUCT_QUANTITY: "productQuantity",
  COURIER: "courier"
}

export const COURIER_LIST = [
  {label: "FEDEX", value: "FEDEX"},
  {label: "USPS", value: "USPS"},
  {label: "UPS", value: "UPS"},
]

export const File_SIZE = 10485760;
export const VIDEO_SIZE = 20971520;

export const FILE_EXTENSIONS = [
  "pdf",
  "txt",
  "plain",
  "xls",
  "vnd.ms-excel",
  "xlsx",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "doc",
  "msword",
  "docx",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
]

export const IMAGE_COMPRESS_OPTION = {
  maxSizeMB: 1,
  useWebWorker: true,
  maxWidthOrHeight: 1920,
};

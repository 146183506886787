import React, { Component } from "react";
import logo from "../../assets/images-2/logo.png";
import "../../Styles/login.scss";

import GoogleLogin1 from "../SocialLogin/googleLogin";
import FacebookLogin from "../SocialLogin/facebookLogin";
import withNavigate from "../../Components/customHooks/navigate";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signup, vemail, login, logout } from "../../Redux/Actions/authAction";
import { getPageContent } from "../../Redux/Actions/pageAction";
import CryptoJS from "crypto-js";

class Login extends Component {
  statePrevCopy = {};

  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        email: "",
        password: "",
        rememberme: false,
        isacceptedtnc: false,
      },
      content: "",
      title: "",
      errorData: {},
      errorMessage: "",
      vcodeFlag: false,
      isLogedIn: false,
      showLoader: false,
    };
    let rememberme = localStorage.getItem("rememberme");

    if (rememberme) {
      rememberme = CryptoJS.AES.decrypt(rememberme, "remembermesecret$#");
      rememberme = rememberme.toString(CryptoJS.enc.Utf8);
      rememberme = JSON.parse(rememberme);
      this.state.formValues = rememberme;
    }

    this.openModal = React.createRef();
  }

  validateUserInfo = () => {
    let isFormValid = true;
    let formValues = this.state.formValues;
    this.state.errorData = {
      email: [],
      password: [],
    };

    if (formValues.email.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.email = ["Email field cannot be empty."];
    } else if (formValues.email.search("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")) {
      isFormValid = false;
      this.state.errorData.email = [
        "Email address should not contain special character",
      ];
    }

    if (formValues.password.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.password = ["Password field cannot be empty."];
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleLogin = async (event) => {
    event.preventDefault();

    await this.setState({ showLoader: true });

    if (this.validateUserInfo()) {
      if (this.state.formValues.rememberme) {
        const rememberme = CryptoJS.AES.encrypt(
          JSON.stringify(this.state.formValues),
          "remembermesecret$#"
        ).toString();
        localStorage.setItem("rememberme", rememberme);
      } else {
        localStorage.removeItem("rememberme");
      }

      await this.props.login({ ...this.state.formValues });
      if (this.props.isLoggedIn) {
        localStorage.setItem("sellerId", this.props.authData?.id);
        localStorage.setItem("sellerData", JSON.stringify(this.props.authData));
        localStorage.setItem("token", this.props.authData?.token);
        localStorage.removeItem("companyId");
        this.setState({ isLogedIn: true, showLoader: false });
        this.props.navigate("/dashboard");
      } else {
        this.setState({
          errorData: this.props.errorData,
          errorMessage: this.props.errorMessage,
          showLoader: false,
        });
      }
    } else {
      await this.setState({ showLoader: false });
    }
  };

  handleChange = (event, field) => {
    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    const test = this.state;
    if (field === "rememberme") {
      test.formValues[field] = event.target.checked;
    } else {
      test.formValues[field] = event.target.value;
    }
    this.setState({ ...test });
  };

  onCancelTnC = () => {
    this.state.formValues.isacceptedtnc = false;
    this.setState({ ...this.state });
    this.props.logout();
  };

  onAcceptTnC = () => {
    this.state.formValues.isacceptedtnc = true;

    this.setState({ ...this.state });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isFound !== this.props.isFound &&
      nextProps.isFound === false
    ) {
      this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
      // call the api for tnc content
      this.fetchPageContent("terms-and-condition-for-vendor");
      this.openModal.current.click();
    }

    if (nextProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: nextProps.errorMessage,
      });
      // this.props.logout();
    }
  }

  fetchPageContent = async (pageSlug) => {
    const result = await this.props.getPageContent(pageSlug);
    if (result?.data?.data) {
      this.setState({
        content: result.data.data.content,
        title: result.data.data.title,
      });
    }
  };

  render() {
    return (
      <div className="main-body">
        <div className="container">
          <div className="signup">
            <form>
              <div className="signup-head">
                <div className="subcciety-logo">
                  <img src={logo} />
                </div>
                <h2>LOGIN TO YOUR ACCOUNT</h2>
                <p>WELCOME BACK! LOGIN TO ACCESS VENDOR ACCOUNT.</p>
              </div>
              {(() => {
                if (this.state.errorMessage !== "") {
                  return (
                    <div className="alert alert-danger">
                      {this.state.errorMessage}
                    </div>
                  );
                }
              })()}
              <div className="signup-mid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.formValues.email}
                        onChange={(event) => this.handleChange(event, "email")}
                        placeholder="Email Address"
                      />
                      <span className="form-field-error">
                        {this.state.errorData?.email &&
                          this.state.errorData.email[0]}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        value={this.state.formValues.password}
                        onChange={(event) =>
                          this.handleChange(event, "password")
                        }
                        placeholder="Password"
                      />
                      <span className="form-field-error">
                        {this.state.errorData?.password &&
                          this.state.errorData.password[0]}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="signup-trums-check">
                  <div className="sign-check">
                    <label className="custom-check">
                      REMEMBER ME
                      <input
                        type="checkbox"
                        checked={this.state.formValues.rememberme}
                        onChange={(event) =>
                          this.handleChange(event, "rememberme")
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>

              <button
                style={{ display: "none" }}
                ref={this.openModal}
                type="button"
                data-toggle="modal"
                data-target="#exampleModal"
              ></button>

              <div
                className="modal fade modal-blog"
                id="exampleModal"
                data-backdrop="static"
                data-keyboard="false"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Terms & Conditions
                      </h5>
                      <button
                        type="button"
                        onClick={this.onCancelTnC}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="modal-blog-detail">
                        <div className="container-fluid row">
                          <h1>
                            <b>{this.state.title}</b>
                          </h1>
                          <div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.content
                                  ? this.state.content
                                  : `-`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>

                      <label
                        className="custom-check"
                        style={{ marginTop: "20px" }}
                      >
                        accept terms of use
                        <input type="checkbox" defaultChecked={true} />
                        <span className="checkmark"></span>
                      </label>
                      <div className="button-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.onCancelTnC}
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.onAcceptTnC}
                          data-dismiss="modal"
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="signup-bottom">
                <div className="signup-submit">
                  <button
                    onClick={(event) => this.handleLogin(event)}
                    type="submit"
                  >
                    sign in
                    {(() => {
                      if (this.state.showLoader) {
                        return (
                          <span className="spinner-border spinner-border-sm ml-1"></span>
                        );
                      }
                    })()}
                  </button>
                </div>
                <div className="icon-same-line">
                  <GoogleLogin1
                    isacceptedtnc={
                      this.props.loginType === "google"
                        ? this.state.formValues.isacceptedtnc
                        : null
                    }
                  ></GoogleLogin1>
                  <FacebookLogin
                    isacceptedtnc={
                      this.props.loginType === "facebook"
                        ? this.state.formValues.isacceptedtnc
                        : null
                    }
                  ></FacebookLogin>
                </div>

                <div className="forgot-password">
                  <Link to={"/forgot-password"}>FORGOT YOUR PASSWORD?</Link>
                </div>
                <div className="already-account-link">
                  <p>
                    don't have an account?{" "}
                    <Link to={"/signup"}>register now</Link>
                  </p>
                </div>
                <div className="policy-box">
                  <Link to="/page/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>
                  <Link to={"/page/terms-of-use"} target="_blank">Terms of Use</Link>
                  <Link to={"/page/support"} target="_blank">Support</Link>
                </div>
              </div>
            </form>
          </div>

          <div className="signup-footer">
            <p>© {new Date().getFullYear()} Subsciety. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isLoggedIn: state.authReducer.isLoggedIn,
    isFound: state.authReducer.isFound,
    loginType: state.authReducer.loginType,
    errorMessage: state.commonReducer.errorMessage,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { signup, vemail, login, logout, getPageContent },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Login));
